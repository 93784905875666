import React, { useEffect } from 'react';
import emailjs from '@emailjs/browser'; // Importing emailjs-com for sending emails
import bgpage from './images/contact_banner.webp'
import './Stlye.css';
import contleft from "./images/cont-left.webp";
import { NavLink } from 'react-router-dom';
import MetaTags from "./MetaTags";
import  ContactSubmitForm from "./ContactSubmitForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
function ContactUs() {

  // Initialize EmailJS
  useEffect(() => {
    emailjs.init('II-fnB7G49fYlfyTr');
  }, []);

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Send email using emailjs
    emailjs.sendForm('service_ofw3ypm', 'template_ki9qfe5', e.target)
      .then((result) => {
        console.log(result.text);
        alert('Your message has been sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('There was an error sending your message. Please try again later.');
      });
    // Clear form fields after submission
    e.target.reset();
  };

  return (
    <>
      <section className="innr_banner"  style={{ 
        backgroundImage: `url(${bgpage})` 
      }}>
        <MetaTags
        title="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        description="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors in Australia.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors."
        keywords="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        identifyUrl="https://smartresourcesqld.com.au/contact-us"
        DCTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        DCDescription="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors."
        DCSubject="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        Canonical="https://smartresourcesqld.com.au/contact-us"
        ogImage="https://smartresourcesqld.com.au/static/media/smart-banner-2.09079fa08d21da7d6cbb.webp"
        ogTitle="Real Estate, Mining, Petroleum, Data Science, Angel Investor Consulting service"
        ogDescription="Smart Resources QLD offers consulting services for Real Estate, Mining, Petroleum, Data Science, and Angel Investors in Australia.  Whether you're looking for expertise in Real Estate, navigating the Mining or Petroleum industries, or leveraging Data Science for better decision-making, Smart Resources QLD can help. We also provide guidance for Angel Investors"
        ogUrl="https://smartresourcesqld.com.au/contact-us"
      />
        <div className="page_title_innr">
          <div className="page_title">Contact Us</div>
          <ul className="bread_crumb">
            <li> <NavLink exact="exact" className="nav-link" to="/">Home</NavLink></li>
            <li>Contact Us</li>
          </ul>
        </div>
      </section>

      <section className="cont_partts">
        <div className="container">
          <div className="cont_innr_left">
            <div className="cont_innr_innr_left">
              <div className="ct-fancy-box wow fadeInUp">
                <div className="ct_item--icon">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                  
                </div>
                <div className="ct_item--holder">
                  <h3>Head office address:</h3>
                  <div className="item_description_ct">
                    Suite 5 "Smithfield Business Centre" 1057 Captain Cook Highway, Smithfield QLD 487
                  </div>
                </div>
              </div>

              <div className="ct-fancy-box wow fadeInUp">
                <div className="ct_item--icon">
                <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="ct_item--holder">
                  <h3>Call for help:</h3>
                  <div className="item_description_ct">
                  <NavLink to="tel: 1300 644 855"> 1300 644 855</NavLink>
                  </div>
                </div>
              </div>
              <div className="ct-fancy-box wow fadeInUp">
                <div className="ct_item--icon">
                <FontAwesomeIcon icon={faEnvelope} />
                  
                </div>
                <div className="ct_item--holder">
                  <h3>Mail for information:</h3>
                  <div className="item_description_ct">
                    <NavLink to="mailto:reachus@smartresourcesqld.com.au">reachus@smartresourcesqld.com.au</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cont_form">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="cont_form_left">
                <div className="cont_form_img">
                  <img src={contleft} className="img-fluid" alt="contact" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="cont_form_right">
                <h1>Make a free consultation with our expert team to solve your problems.</h1>
                <div className="cont_formmm">
                  <ContactSubmitForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
