import React from 'react';
import { FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa'; 
import { FaPhone } from "react-icons/fa6";
import  ContactSubmitForm from "./ContactSubmitForm";
import emailjs from '@emailjs/browser'; // Importing emailjs-com for sending emails
import './Stlye.css';
// var contactSubmit;
const ContactUs = () => {
  return (
    <section className="cont_us_partts">
      <div className="test_heading">
        <div className="test_heading_innr">
          <div className="test_heading_innr_left left_prj">
            <div className="about_left_heading left_heading_prj">
              <div className="abt_sub_title">Contact Us</div>
              <h3 className="wow fadeInLeft">
                Our client, global analytical techno company, wanted to build market.
              </h3>
            </div>
          </div>
          <div className="test_heading_innr_right prj_right wow fadeInRight">
            <p>
            Get in touch with us today to unlock the full potential of your business. Whether you're seeking consultancy services or exploring partnership opportunities, our team at Smart Resources QLD is ready to collaborate and drive your success forward.
            </p>
          </div>
        </div>
      </div>
      <div className="cont_innr">
        <div className="cont_innr_left">
          <div className="cont_innr_innr_left">
            <ContactInfo icon={<FaMapMarkerAlt />} title="Head office address:" description="Suite 5 “Smithfield Business Centre” 1057 Captain Cook Highway, Smithfield QLD 487" />
            <ContactInfo  icon={<FaPhone />}  title="Call for help:" description=" 1300 644 855" />
            <ContactInfo icon={<FaEnvelope />} title="Mail for information:" description="reachus@smartresourcesqld.com.au" />
          </div>
        </div>
        <div className="cont_innr_right wow fadeInUp">
          <ContactSubmitForm />
        </div>
      </div>
    </section>
  );
};

const ContactInfo = ({ icon, title, description }) => {
  return (
    <div className="ct-fancy-box wow fadeInUp">
      <div className="ct_item--icon">{icon}</div>
      <div className="ct_item--holder">
        <h3>{title}</h3>
        <div className="item_description_ct">{description}</div>
      </div>
    </div>
  );
};


export default ContactUs;
